import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from "docz";
import { FontSizeScale, TypoStyles, SpaceScale, Rhythms } from "./components/Typography";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "typography-and-spacing"
    }}>{`Typography and spacing`}</h1>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>

    <h3 {...{
      "id": "typo-styles"
    }}>{`Typo styles`}</h3>
    <TypoStyles mdxType="TypoStyles" />
    <p>{`Our font size scale contains these sizes:`}</p>
    <FontSizeScale mdxType="FontSizeScale" />
    <h2 {...{
      "id": "space-scale"
    }}>{`Space scale`}</h2>
    <SpaceScale mdxType="SpaceScale" />
    <h3 {...{
      "id": "space-rhythms"
    }}>{`Space rhythms`}</h3>
    <p>{`Space rhythms is a collection of pre-defined spaces, tailored for the different breakpoints. It can be used for every space property, eg `}<inlineCode parentName="p">{`<Box mb="sm" p="xl" />`}</inlineCode>{`.`}</p>
    <Rhythms mdxType="Rhythms" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      